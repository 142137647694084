$(function() {
  var minDate =  moment().subtract(7,'d').startOf('day');
  var today = moment().endOf('day');
  $('#proxy_error_start_datetimepicker, #proxy_error_end_datetimepicker').datetimepicker({
    format: 'YYYY-MM-DD',
    minDate: minDate,
    maxDate: today,
    useCurrent: false,
    allowInputToggle: true
  });
});
