try {
  var client = new Keen({
    projectId: "571f877d07271969a3203d11",
    readKey: "a6c09b5f071a42f9e806ab80173b507ab430ea63beff31409b614a641c317846fd9dd8b5e0ef52582d0bb9b68b43335e27caf007917d395dcbec75020c8bad13c2b54001a5d68ba414e56ba77f5c3f5f8be3c06bad503b375c18176a71a5179f" 
  });

  Keen.ready(function(){

    var query = new Keen.Query("sum", {
      eventCollection: "ads_production",
      interval: "hourly",
      targetProperty: "updated.count",
      timeframe: "this_336_hours",
      timezone: "UTC"
    });

    client.draw(query, document.getElementById("ads_hourly_chart"), {
      chartType: "columnchart",
      height: 360,
      title: " "
      // Custom configuration here
    });

  });
  
} catch (e) {
    if (e instanceof ReferenceError) {
        // Handle error as necessary
    }
}

