/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

export default class SnapshotMap {
    constructor(report_url, opts) {
        this.opts = opts;
        this.propertyKey = 'SNAPSHOTS';
        this.storage = []
        this.mapStyle = [
            { 'stylers': [ { 'visibility': 'on' } ] },
            {
                'featureType': 'landscape',
                'elementType': 'geometry',
                'stylers': [
                    { 'visibility': 'on' },
                    { 'color': '#05861c' }
                ]
            },
            {
                'featureType': 'water',
                'elementType': 'geometry',
                'stylers': [
                    { 'visibility': 'on' },
                    { 'color': '#bfd4ff' }
                ]
            }
        ];
        console.log('Map Created!');
        this.initMap(report_url);
    }

    initMap = function(report_url){
        const mapOpts = {
            styles: this.mapStyle,
            mapTypeId: 'satellite',
            center: {
                lat: 40.017,
                lng: -105.28
            },
            zoom: 3,
            disableDoubleClickZoom: true
        };

        const map = new (google.maps.Map)(document.getElementById('map'), mapOpts);
        map.data.loadGeoJson(report_url, {idPropertyName: 'CODE'});

        google.maps.event.addListenerOnce(map.data, 'addfeature', () => google.maps.event.trigger(document.getElementById('map'), 'change'));

        const selectBox = document.getElementById('census-variable');

        const self = this;

        google.maps.event.addDomListener(selectBox, 'change', function() {
            self.changeDisplayProperty(selectBox.options[selectBox.selectedIndex].value);
            self.styleColorKey();
            map.data.setStyle(self.styleFeature.bind(self));
            map.data.addListener('mouseover', self.mouseInToRegion.bind(self));
            map.data.addListener('mouseout', self.mouseOutOfRegion);
            google.maps.event.clearListeners(map.data, 'click');
            return map.data.addListener('click', self.clickRegion.bind(self));
        });

        google.maps.event.trigger(selectBox, 'change');

        map.controls[google.maps.ControlPosition.LEFT_CENTER].push(document.getElementById('data-box'));
        map.controls[google.maps.ControlPosition.TOP_CENTER].push(document.getElementById('controls'));
        map.controls[google.maps.ControlPosition.TOP_RIGHT].push(document.getElementById('fips-map-search'));

        return $('#fips-map-search').keyup(ev => this.filterPropertiesByCode(ev, map));
    };

    changeDisplayProperty = function(value) {
        this.propertyKey = value;
    };

    filterPropertiesByCode = function(event, map){
        const value = String(event.target.value);
        map.data.forEach(function(feature){
            const code = feature.getProperty('CODE');
            const name = feature.getProperty('NAME');
            if (String(code).startsWith(value) || String(name.toLowerCase()).startsWith(value.toLowerCase())) {
                return feature.setProperty('event_state2', 'marked');
            } else {
                return feature.setProperty('event_state2', 'filtered');
            }
        });
        return map.data.setStyle(this.styleFeature.bind(this));
    };

    styleFeature = function(feature) {
        let colorStr, visible, outlineWeight, zIndex;
        const val = feature.getProperty(this.propertyKey);
        if (val === 0) {
            colorStr = 'hsla(0,0%,100%,0.25)';
        } else if (val === null) {
            colorStr = 'hsla(0,0%,100%,0)';
        } else {
            const delta = this.countDelta(val);
            const color = this.deltaColor(delta);
            colorStr = this.arrToHslString(color);
        }

        outlineWeight = 0.5;
        zIndex = 1;
        ({visible: true});

        if (feature.getProperty('event_state') === 'hover') {
            outlineWeight = (zIndex = 3);
        }

        if (feature.getProperty('event_state2') === 'filtered') {
            visible = false;
        }

        return {
            title: feature.getProperty(this.propertyKey),
            strokeWeight: outlineWeight,
            strokeColor: '#000',
            fillColor: colorStr,
            fillOpacity: 1,
            clickable: true,
            visible
        };
    };

    styleColorKey = function() {
        const censusMinElement = document.getElementById('census-min');
        const censusMaxElement = document.getElementById('census-max');
        censusMinElement.innerText = this.opts[this.propertyKey]['censusMin'];
        censusMaxElement.innerText = this.opts[this.propertyKey]['censusMax'];
        const colorKey = document.getElementById('legend-color-key');
        const lowColor = this.opts[this.propertyKey]['low'];
        const highColor = this.opts[this.propertyKey]['high'];
        return colorKey.style.background = this.colorGradient(lowColor, highColor);
    };

    renderRegionsFromStorage = function() {
        const container = document.getElementById('storage-container');
        container.innerHTML = '';
        self = this;
        return this.storage.forEach(function(feature) {
            const child_div = self.serializedProperties(feature);
            const closeActions = document.createElement('div');
            closeActions.classList.add('close');
            closeActions.innerHTML = "x";
            closeActions.addEventListener('click', () => self.clickRegion({'feature': feature}));
            child_div.appendChild(closeActions);
            return container.appendChild(child_div);
        });
    };

    clickRegion = function(e) {
        if (e.feature.getProperty('event_state3') === 'clicked') {
            e.feature.setProperty('event_state3', false);
            const index = this.storage.indexOf(e.feature);
            if (index > -1) {
                this.storage.splice(index, 1);
            }
        } else {
            e.feature.setProperty('event_state3', 'clicked');
            this.storage.push(e.feature);
        }
        return this.renderRegionsFromStorage();
    };

    removeFromStorage = function(e) {
        if (e.feature.getProperty('event_state3') === 'clicked') {
            e.feature.setProperty('event_state3', false);
            const index = storage.indexOf(e.feature);
            if (index > -1) {
                this.storage.splice(index, 1);
            }
        } else {
            e.feature.setProperty('event_state3', 'clicked');
            this.storage.push(e.feature);
        }
        return this.renderRegionsFromStorage();
    };

    mouseInToRegion = function(e) {
        e.feature.setProperty('event_state', 'hover');
        const val = e.feature.getProperty(this.propertyKey);
        const percent = this.countDelta(val) * 100;

        document.getElementById('data-label').textContent = e.feature.getProperty('NAME') + ' (' + e.feature.getProperty('CODE') + ')';

        document.getElementById('data-value').innerHTML = '';
        document.getElementById('data-value').appendChild(this.serializedProperties(e.feature));

        document.getElementById('data-box').style.display = 'block';
        document.getElementById('data-caret').style.display = 'block';
        return document.getElementById('data-caret').style.paddingLeft = percent + '%';
    };

    serializedProperties = function(feature) {
        const parent_div = document.createElement('div');
        parent_div.display = 'block';
        parent_div.classList.add('data-property');
        feature.forEachProperty(function(val, prop) {
            if (prop.startsWith('event_state')) {
                return;
            }
            const child_div = document.createElement('div');
            child_div.display = 'block';
            const label = document.createElement('label');
            label.innerHTML = prop + ":&nbsp";
            const value = document.createElement('span');
            value.innerHTML = val;
            child_div.appendChild(label);
            child_div.appendChild(value);
            return parent_div.appendChild(child_div);
        });
        return parent_div;
    };

    mouseOutOfRegion = e => e.feature.setProperty('event_state', 'normal');

    arrToHslString = arr => 'hsl(' + arr[0] + ',' + arr[1] + '%,' + arr[2] + '%)';

    countDelta = function(val, logarithmic) {
        const censusMin  = this.opts[this.propertyKey]['censusMin'];
        const censusMax  = this.opts[this.propertyKey]['censusMax'];
        logarithmic = this.opts[this.propertyKey]['logarithmic'];
        if (logarithmic && (val < 0)) {
            return -Math.log10(val) / Math.log10(censusMax);
        } else if (logarithmic) {
            return Math.log10(val) / Math.log10(censusMax);
        } else {
            return (val - censusMin) / (censusMax - censusMin);
        }
    };

    deltaColor = function(delta) {
        const low  = this.opts[this.propertyKey]['low'];
        const high = this.opts[this.propertyKey]['high'];
        const color = [];
        let i = 0;
        while (i < 3) {
            color[i] = ((high[i] - (low[i])) * delta) + low[i];
            i++;
        }
        return color;
    };

    colorGradient = (lowColor, highColor) => 'linear-gradient(to right,' +
        this.arrToHslString(lowColor) + ', ' +
        this.arrToHslString(this.deltaColor(0.25)) + ', ' +
        this.arrToHslString(this.deltaColor(0.50)) + ', ' +
        this.arrToHslString(this.deltaColor(0.75)) + ', ' +
        this.arrToHslString(highColor);
}

