$(function () {
  $("#count_spikes_datetimepicker").datetimepicker(
    {
      format: 'YYYY-MM-DD',
      maxDate: moment(),
      useCurrent: false,
      allowInputToggle: true
    }
  )
  $("#count_spikes_state").select2()
});
