/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(function() {
  () => colorPanels()
});

  // make select a date reload a page to load/create report for that date
window.reloadPageWithDate = function(ele, path) {
  console.log("URL:", path + $(ele).val());
  return location.href = path + "/" + $(ele).val();
};

// colors panels in despite of checked ads:
//   sucess (green) - if all checked
//   warning (orange) - if adsource has no ads
//   default (gray) - if not all ads are checked
const colorPanels = function() {
  const panels = $('.panel');
  return (() => {
    const result = [];
    for (let panel of Array.from(panels)) {
      if ($(panel).find('.checks').length === 0) {
        $(panel).removeClass('panel-default');
        result.push($(panel).addClass('panel-warning'));
      } else {
        const all_checked = $(panel).find('.checks:checked').length === $(panel).find('.checks').length;
        if (all_checked) {
          $(panel).removeClass('panel-default');
          result.push($(panel).addClass('panel-success'));
        } else {
          $(panel).addClass('panel-default');
          result.push($(panel).removeClass('panel-success'));
        }
      }
    }
    return result;
  })();
};
window.colorPanels = colorPanels
