try {
  var client = new Keen({
    projectId: "571f877d07271969a3203d11",
    readKey: "a6c09b5f071a42f9e806ab80173b507ab430ea63beff31409b614a641c317846fd9dd8b5e0ef52582d0bb9b68b43335e27caf007917d395dcbec75020c8bad13c2b54001a5d68ba414e56ba77f5c3f5f8be3c06bad503b375c18176a71a5179f" 
  });

  Keen.ready(function(){
    // use a variable to ensure timeframe & interval for both queries match
    var interval = "daily"
    var timeframe = "previous_14_days"

    var new_snapshots = new Keen.Query("sum", {
      eventCollection: "snapshots_production",
      interval: interval,
      targetProperty: "new.count",
      timeframe: timeframe,
      timezone: "UTC"
    });

    var active_snapshots = new Keen.Query("sum", {
      eventCollection: "snapshots_production",
      interval: interval,
      targetProperty: "new.is_active",
      timeframe: timeframe,
      timezone: "UTC"
    });

    var broker_snapshots = new Keen.Query("sum", {
      eventCollection: "snapshots_production",
      interval: interval,
      targetProperty: "new.with_brokerage",
      timeframe: timeframe,
      timezone: "UTC"
    });

    var agent_snapshots = new Keen.Query("sum", {
      eventCollection: "snapshots_production",
      interval: interval,
      targetProperty: "new.with_agent",
      timeframe: timeframe,
      timezone: "UTC"
    });

    var address_snapshots = new Keen.Query("sum", {
      eventCollection: "snapshots_production",
      interval: interval,
      targetProperty: "new.with_valid_address",
      timeframe: timeframe,
      timezone: "UTC"
    });

    var chart = new Keen.Dataviz()
      .el(document.getElementById("snapshots_daily_chart"))
      .chartType("columnchart")
      .height(360)
      .chartOptions({
        hAxis: {
          format:'E MMM d',
          // gridlines:  {count: 12}
        } //,
        // vAxis: {
        //   gridlines: {count: 10}
        // }
      })
      .prepare();

    client.run([new_snapshots, active_snapshots, broker_snapshots, agent_snapshots, address_snapshots], function(err, res){ // run the queries

      var result1 = res[0].result  // data from first query
      var result2 = res[1].result  // data from second query
      var result3 = res[2].result  // data from third query
      var result4 = res[3].result  // data from fourth query
      var result5 = res[4].result  // data from fifth query
      var data = []  // place for combined results
      var i=0

      while (i < result1.length) {

          data[i]={ // format the data so it can be charted
              timeframe: result1[i]["timeframe"],
              value: [
                  { category: "New", result: result1[i]["value"] },
                  { category: "Active", result: result2[i]["value"] },
                  { category: "Broker", result: result3[i]["value"] },
                  { category: "Agent", result: result4[i]["value"] },
                  { category: "Valid Address", result: result5[i]["value"] }
              ]
          }
          if (i == result1.length-1) { // chart the data
          chart
            .parseRawData({ result: data })
            .render();
          }
          i++;
      } // while
    }); // Client.run
  }); // Keen.ready

} catch (e) {
    if (e instanceof ReferenceError) {
        // Handle error as necessary
    }
}
