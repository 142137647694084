try {
  var client = new Keen({
    projectId: "571f877d07271969a3203d11",
    readKey: "a6c09b5f071a42f9e806ab80173b507ab430ea63beff31409b614a641c317846fd9dd8b5e0ef52582d0bb9b68b43335e27caf007917d395dcbec75020c8bad13c2b54001a5d68ba414e56ba77f5c3f5f8be3c06bad503b375c18176a71a5179f" 
  });

  Keen.ready(function(){

    var query = new Keen.Query("sum", {
      eventCollection: "snapshots_production",
      interval: "hourly",
      targetProperty: "total.active_valid_address_with_agent_percent",
      timeframe: "this_336_hours",
      timezone: "UTC"
    });

    client.draw(query, document.getElementById("snapshots_is_active_valid_address_with_agent_percent"), {
      chartType: "linechart",
      height: 360,
      title: " "
      // Custom configuration here
    });

  });
} catch (e) {
    if (e instanceof ReferenceError) {
        // Handle error as necessary
    }
}



// Keen.ready(function(){
//   // use a variable to ensure timeframe & interval for both queries match
//   var interval = "hourly"
//   var timeframe = "this_14_days"
//
//   var is_active_valid_address_with_agent_percent = new Keen.Query("average", {
//     eventCollection: "snapshots_production",
//     interval: interval,
//     targetProperty: "total.active_valid_address_with_agent_percent",
//     timeframe: timeframe,
//     timezone: "UTC"
//   });
//
//   var chart = new Keen.Dataviz()
//     .el(document.getElementById("snapshots_is_active_valid_address_with_agent_percent"))
//     .chartType("linechart")
//     .height(360)
//     .chartOptions({
//       hAxis: {
//         format:'E MMM d',
//         // gridlines:  {count: 12}
//       },
//       vAxis: {
//         gridlines: {count: 10},
//         viewWindow: {max: 1, min: 0}
//       },
//       pointSize: 5
//     })
//     .prepare();
//
//   client.run([is_active_valid_address_with_agent_percent], function(err, res){ // run the queries
//
//     var result1 = res[0].result  // data from first query
//     var data = []  // place for combined results
//     var i=0
//
//     while (i < result1.length) {
//
//         data[i]={ // format the data so it can be charted
//             timeframe: result1[i]["timeframe"],
//             value: [
//                 { category: "Is Active with Agent %", result: result1[i]["value"] }
//             ]
//         }
//         if (i == result1.length-1) { // chart the data
//         chart
//           .parseRawData({ result: data })
//           .render();
//         }
//         i++;
//     } // while
//   }); // Client.run
// }); // Keen.ready
