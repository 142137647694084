
$(function() {
  var table = $('.ad-source-cass-errors').DataTable({
    autoWidth: true,
    pagingType: 'full_numbers',
    keys: true,
    "order": [[5, 'desc'], [3, 'desc']],
    'pageLength': 50,
    'fnDrawCallback': function() {
      $('[data-toggle="tooltip"]').tooltip()
    }
  });
  // keydown delay
  var typingTimer;

  var filters_classes = '.total-cass-parse-errors-min, .total-cass-parse-errors-max'
  var min, max, column_index, active_only;

  $(filters_classes).on('keyup', function() {
    typingTimer = setTimeout(filterRows, 2000);
  });

  $(filters_classes).on('keydown', function() {
    clearTimeout(typingTimer);
  });

  $('.only-active-ad-sources').change(table.draw);

  // filtering
  function filterRows() {
    min = parseFloat($('.total-cass-parse-errors-min').val(), 10);
    max = parseFloat($('.total-cass-parse-errors-max').val(), 10);
    column_index = $('.ad-source-cass-errors thead th').index($('.ad-source-cass-errors thead th').filter(":contains(total cass parse errors)")[0]);

    table.draw();
  }

  // by min and max errors
  $.fn.dataTable.ext.search.push(
    function(settings, data, dataIndex) {
      if (column_index === undefined) {
        return true
      }

      var cass_parse_errors = parseFloat(data[column_index].split(',').join('')) || 0;

      if ( ( isNaN( min ) && isNaN( max ) ) ||
           ( isNaN( min ) && cass_parse_errors <= max ) ||
           ( min <= cass_parse_errors && isNaN( max ) ) ||
           ( min <= cass_parse_errors && cass_parse_errors <= max ) ) {
        return true;
      }
      return false;
    }
  );

  // by active ad_sources
  $.fn.dataTable.ext.search.push(
    function(settings, data, dataIndex) {
      var adSourceStatus = data[1];

      if ($('.only-active-ad-sources').is(':checked') === true) {
        return adSourceStatus === "true";
      }
      return true;
    }
  );

  table.draw();

  $("#q").keyup(function() {
    var checkbox = $("#is_active")
    var label = $('label[for='+  checkbox.attr('id')  +']');
    
    if ($(this).val().length > 0) {
      checkbox.prop( "disabled", false )
      label.removeClass('text-muted')
    } else {
      checkbox.prop( "disabled", true )
      label.addClass('text-muted')
    }
  });

  $(function () {
    var today = new Date();
    $('#last-date').datetimepicker({
      format: 'YYYY-MM-DD',
      maxDate: today,
      useCurrent: false
    });
  });

  $(function () {
    var myTextArea = document.getElementsByClassName('yaml-field')[0]
    if (myTextArea != undefined) {
      var myCodeMirror = CodeMirror(document.body);
      var defaultValue = myTextArea.value
      var editor = CodeMirror.fromTextArea(myTextArea, {
        value: defaultValue,
        lineNumbers: true,
        mode: "yaml",
        gutters: ["CodeMirror-lint-markers"],
        lint: true
      });
      myTextArea.setAttribute('data-codemirror', 'true');
      // null indicates no change of dimension (w,h)
      editor.setSize(null, 100);

      editor.on("change", function(cm, n) {
        cm.save()
      });
    }
  });
})
