$(function() {
  if ($('.tablejs thead th').length > 7) {
    var order =  [[ 5, "asc" ]]
  } else {
    var order =  [[ 0, "desc" ]]
  }

  var reportTable = $('.tablejs').DataTable({
    // ajax: ...,
    autoWidth: true,
    pagingType: 'full_numbers',
    keys: true,
    "order": order,
    'pageLength': 50,
    // serverSide: true,
    'fnDrawCallback': function(){
      $('[data-toggle="tooltip"]').tooltip()
    }
    // Optional, if you want full pagination controls.
    // Check dataTables documentation to learn more about available options.
    // http://datatables.net/reference/option/pagingType
  });

  var timer = function(name) {
      var start = new Date();
      return {
          stop: function() {
              var end  = new Date();
              var time = end.getTime() - start.getTime();
              console.log('Timer:', name, 'finished in', time, 'ms');
          }
      }
  };

  // Keydown delay
  var typingTimer;
  var doneTypingInterval = 2000;

  var filters_ids = '#variance-min, #variance-max, #s-deviation-min, #s-deviation-max, #current-deviation-min, #current-deviation-max, #population-min, #population-max'
  $(filters_ids).on( 'keyup', function() {
    typingTimer = setTimeout(doneTyping, doneTypingInterval);
  });

  $(filters_ids).on( 'keydown', function() {
    clearTimeout(typingTimer);
  });

  // Benchmark
  function doneTyping () {
    var t = timer('benchmark');
    reportTable.draw();
    t.stop();
  }

  $.fn.dataTable.ext.search.push(
      function( settings, data, dataIndex ) {
          if ($('#current-deviation-min').val() === "" && $('#current-deviation-max').val() === "") {
            return true
          }
          var min = parseFloat( $('#current-deviation-min').val(), 10 );
          var max = parseFloat( $('#current-deviation-max').val(), 10 );
          row_index = $('.tablejs thead th').index($('.tablejs thead th').filter(":contains(current deviation)")[0])
          var variance = parseFloat( data[row_index] ) || 0;
          if ( ( isNaN( min )      && isNaN( max ) ) ||
               ( isNaN( min )      && variance <= max ) ||
               ( min <= variance   && isNaN( max ) ) ||
               ( min <= variance   && variance <= max ) )
          {
              return true;
          }
          return false;
      }
  );

  $.fn.dataTable.ext.search.push(
      function( settings, data, dataIndex ) {
          if ($('#variance-min').val() === "" && $('#variance-min').val() === "") {
            return true
          }
          var min = parseFloat( $('#variance-min').val(), 10 );
          var max = parseFloat( $('#variance-max').val(), 10 );
          row_index = $('.tablejs thead th').index($('.tablejs thead th').filter(":contains(variance)")[0])
          var variance = parseFloat( data[row_index] ) || 0;
          if ( ( isNaN( min )      && isNaN( max ) ) ||
               ( isNaN( min )      && variance <= max ) ||
               ( min <= variance   && isNaN( max ) ) ||
               ( min <= variance   && variance <= max ) )
          {
              return true;
          }
          return false;
      }
  );

  $.fn.dataTable.ext.search.push(
      function( settings, data, dataIndex ) {
          if ($('#s-deviation-min').val() === "" && $('#s-deviation-min').val() === "") {
            return true
          }
          var min = parseFloat( $('#s-deviation-min').val(), 10 );
          var max = parseFloat( $('#s-deviation-max').val(), 10 );
          row_index = $('.tablejs thead th').index($('.tablejs thead th').filter(":contains(standard seviation)")[0])
          var s_deviation = parseFloat( data[row_index] ) || 0;
          if ( ( isNaN( min )      && isNaN( max ) ) ||
               ( isNaN( min )      && s_deviation <= max ) ||
               ( min <= s_deviation   && isNaN( max ) ) ||
               ( min <= s_deviation   && s_deviation <= max ) )
          {
              return true;
          }
          return false;
      }
  );

  $.fn.dataTable.ext.search.push(
      function( settings, data, dataIndex ) {
        if ($('#population-min').val() === "" && $('#population-max').val() === "") {
          return true
        }
        if (data[7] == undefined) {
          return true
        }
        var min = parseFloat( $('#population-min').val(), 10 );
        var max = parseFloat( $('#population-max').val(), 10 );
        row_index = $('.tablejs thead th').index($('.tablejs thead th').filter(":contains(population)")[0])
        var population = parseFloat( data[row_index].split(',').join('') ) || 0;
        if ( ( isNaN( min )      && isNaN( max ) ) ||
             ( isNaN( min )      && population <= max ) ||
             ( min <= population   && isNaN( max ) ) ||
             ( min <= population   && population <= max ) )
        {
            return true;
        }
        return false;
      }
  );
  reportTable.draw();
})


$(function() {
  var table = $('#bulk-coverage-report').DataTable({
    // ajax: ...,
    autoWidth: true,
    pagingType: 'full_numbers',
    processing: true,
    keys: true,
    'pageLength': 50,
    'order': [[ 0, "asc" ]]
    // Optional, if you want full pagination controls.
    // Check dataTables documentation to learn more about available options.
    // http://datatables.net/reference/option/pagingType
  });
});
