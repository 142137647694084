try {
  var client = new Keen({
    projectId: "571f877d07271969a3203d11",
    readKey: "a6c09b5f071a42f9e806ab80173b507ab430ea63beff31409b614a641c317846fd9dd8b5e0ef52582d0bb9b68b43335e27caf007917d395dcbec75020c8bad13c2b54001a5d68ba414e56ba77f5c3f5f8be3c06bad503b375c18176a71a5179f" 
  });

  Keen.ready(function(){
    // use a variable to ensure timeframe & interval for both queries match
    var interval = "daily"
    var timeframe = "previous_14_days"

    var active_percent = new Keen.Query("average", {
      eventCollection: "snapshots_production",
      interval: interval,
      targetProperty: "new.is_active_percent",
      timeframe: timeframe,
      timezone: "UTC"
    });

    var broker_percent = new Keen.Query("average", {
      eventCollection: "snapshots_production",
      interval: interval,
      targetProperty: "new.with_brokerage_percent",
      timeframe: timeframe,
      timezone: "UTC"
    });

    var agent_percent = new Keen.Query("average", {
      eventCollection: "snapshots_production",
      interval: interval,
      targetProperty: "new.with_agent_percent",
      timeframe: timeframe,
      timezone: "UTC"
    });

    var address_percent = new Keen.Query("average", {
      eventCollection: "snapshots_production",
      interval: interval,
      targetProperty: "new.with_valid_address_percent",
      timeframe: timeframe,
      timezone: "UTC"
    });

    var chart = new Keen.Dataviz()
      .el(document.getElementById("snapshots_daily_percentages_chart"))
      .chartType("linechart")
      .height(360)
      .chartOptions({
        hAxis: {
          format:'E MMM d',
          // gridlines:  {count: 12}
        },
        vAxis: {
          gridlines: {count: 10},
          viewWindow: {max: 1, min: 0}
        },
        pointSize: 5
      })
      .prepare();

    client.run([active_percent, broker_percent, agent_percent, address_percent], function(err, res){ // run the queries

      var result1 = res[0].result  // data from first query
      var result2 = res[1].result  // data from second query
      var result3 = res[2].result  // data from third query
      var result4 = res[3].result
      var data = []  // place for combined results
      var i=0

      while (i < result1.length) {

          data[i]={ // format the data so it can be charted
              timeframe: result1[i]["timeframe"],
              value: [
                  { category: "Active", result: result1[i]["value"] },
                  { category: "Broker", result: result2[i]["value"] },
                  { category: "Agent", result: result3[i]["value"] },
                  { category: "Valid Address", result: result4[i]["value"] }
              ]
          }
          if (i == result1.length-1) { // chart the data
          chart
            .parseRawData({ result: data })
            .render();
          }
          i++;
      } // while
    }); // Client.run
  }); // Keen.ready
  
} catch (e) {
    if (e instanceof ReferenceError) {
        // Handle error as necessary
    }
}
