$(function() {
  $("#ad_ad_source_id").select2();
  $("#new_ad_ad_source_id").select2();
  $("#filter_ads_ad_source_id").select2({
    placeholder: 'Select Ad Source',
    allowClear: true
  });

  var today = new Date();
  $('#ads_datetimepicker_from, #ads_datetimepicker_to').datetimepicker({
    format: 'YYYY-MM-DD',
    maxDate: today,
    useCurrent: false,
    allowInputToggle: true
  });
});
