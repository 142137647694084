function manage_where_elements_based_on_operator() {
  if ($("#where_operator option:selected").val() == "starts with") {
    $("#where-value-notice").show();
    $("#where_value").prop("disabled", false);
  } else if ($("#where_operator option:selected").val() == "IS NULL" ||
             $("#where_operator option:selected").val() == "IS TRUE" ||
             $("#where_operator option:selected").val() == "IS FALSE") {
    $("#where-value-notice").hide();
    $("#where_value").prop("disabled", true);
  } else {
    $("#where-value-notice").hide();
    $("#where_value").prop("disabled", false);
  }
}

function toggle_where_elements() {
  var state = $("#where_enabled").is(":checked")
  $("#where_attribute").prop("disabled", state);
  $("#where_operator").prop("disabled", state);
  $("#where_value").prop("disabled", state);
}

$(function() {
  $("#ad_source_id").select2();
  $("#override_attribute").select2();
  $("#where_attribute").select2();

  // where inputs
  $("#where_operator").change(function() {
    manage_where_elements_based_on_operator();
  });

  manage_where_elements_based_on_operator();

  $("#where_enabled").change(function() {
    toggle_where_elements();
  });

  toggle_where_elements();

  // override with value input
  if ($("#override_with_value_nil").is(":checked")) {
    $("#override_with_value").prop("disabled", true);
  }

  $("#override_with_value_nil").change(function() {
    $("#override_with_value").prop("disabled", function(i, v) { return !v; });
  });
});
