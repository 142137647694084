/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("@rails/ujs").start()
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import 'bootstrap/dist/js/bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

// cannot load datatables without imports-loader lib, recommended way by:
// https://stackoverflow.com/questions/57964095/rails-6-webpack-datatables-jquery
require('imports-loader?define=>false!datatables.net')(window, $);
require('imports-loader?define=>false!datatables.net-bs')(window, $);
require('imports-loader?define=>false!datatables.net-dt')(window, $)
require('imports-loader?define=>false!datatables.net-select')(window, $)
require('imports-loader?define=>false!datatables.net-select-dt')(window, $)
import 'datatables.net-bs/css/dataTables.bootstrap.min'

import moment from 'moment'
window.moment = moment

$.fn.datetimepicker = require('eonasdan-bootstrap-datetimepicker')
import 'eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css'

require("chartkick")
require("chart.js")

require('select2/dist/js/select2.full');
import 'select2/dist/css/select2.min.css'

// CodeMirror
import CodeMirror from 'codemirror/lib/codemirror.js';
import 'codemirror/lib/codemirror.css';
import 'codemirror/addon/lint/lint'
import 'codemirror/addon/lint/yaml-lint'
import 'codemirror/mode/yaml/yaml'
window.CodeMirror = CodeMirror

// js-yaml
require('js-yaml');

// custom files
import './custom-js/ad_sources'
import './custom-js/ads'
import './custom-js/audit_request_report'
import './custom-js/bootstrap.js'
import './custom-js/data_tables'
import './custom-js/override_ad_attributes'
import './custom-js/proxy_errors'
import './custom-js/reports_ads_daily'
import './custom-js/reports_ads_hourly'
import './custom-js/reports_snapshots_daily_percentages'
import './custom-js/reports_snapshots_daily'
import './custom-js/reports_snapshots_hourly_total'
import './custom-js/reports_snapshots_hourly'
import './custom-js/reports_snapshots_is_active_valid_address_with_agent'
import './custom-js/reports_ad_sources_with_count_spikes'
import './custom-js/site_map_urls'
import './custom-js/site_maps'
import './custom-js/forms'

import SnapshotMap from './custom-js/SnapshotMap.js'
window.SnapshotMap = SnapshotMap

import './custom-css/ads.css'
import './custom-css/ad_sources.css'
import './custom-css/bootstrap_and_overrides.css'
import './custom-css/cass_parse_errors.css'
import './custom-css/custom_styles.css'
import './custom-css/not_found.css'
import './custom-css/override_ad_attributes.css'
import './custom-css/properties.css'
import './custom-css/snapshot_map.css'
import './custom-css/snapshots-panel.css'

